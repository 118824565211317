.selectStyle {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
    border: 1px solid #ced4da;    
    color: #495057;
}