.cardStyle{
    background-color: #ECEFF1;
    padding: 0;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 20px;
    margin-bottom: 0px;
    border-radius: 15px;    
}

@media only screen and (min-width: 960px) {
    .cardStyle {
        max-width: 22%
    }
  }