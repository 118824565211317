.skiptranslate.goog-te-banner-frame {
    display: none;
}

.google_original_language {
    border: 0.5px solid hsla(0, 0%, 50%, 0.4);
    border-radius: 25px;
    color: #545cd8;
    background-color: white;
    padding: 0.2rem 0.5rem;
}

#google_translate_element .goog-te-gadget {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

#google_translate_element .goog-te-gadget .goog-te-gadget-simple {
    border: 0.5px solid hsla(0, 0%, 50%, 0.4);
    border-radius: 25px;
    padding: 0.2rem 0.5rem;
    margin-right: 0.5rem;
    color: #545cd8;
}

#google_translate_element .goog-te-gadget .goog-te-gadget-simple .goog-te-menu-value {
    color: #545cd8;
}

.goog-te-menu-frame.skiptranslate .goog-te-menu2 * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

#goog-gt-tt.skiptranslate {
    display: none !important;
}

html body {
    top: 0px !important;
}

.login-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
}