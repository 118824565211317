.changeTagStyle {
    color: #495057;
    display: flex;
    align-items: center;
    justify-content: left;
    text-decoration: none
}

.changeTagStyle:hover {
    color: #495057;
    text-decoration: none
}