.cardStyleStats {
    margin: 7px;
    padding: 0px;
    background-color: white;
    border: 1px solid rgb(112, 112, 112);
    border-radius: 8px;
    text-align: center;
}

@media only screen and (min-width: 960px) {
    .cardStyleStats {
        max-width: 22%
    }
}