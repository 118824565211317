.searchComponent_labelStyle {
    font-size: 16px;
    font-weight: 600;
    padding: 4px;
    text-align: center;
    background-color: rgb(224, 243, 255);
    color: #495057;
    width: 100%;
}

.searchComponent_inputStyle {
    font-size: 16px;
    font-weight: 400;
    padding: 4px;
    text-align: center;
    background-color: white;
    color: #495057;
    width: 100%;
}

.searchComponent_labelStyle_NoBg_LeftAligned {
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    color: rgb(71, 73, 79);
}

.searchComponent_inputStyle_LeftAligned {
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 4px;
    text-align: left;
    background-color: white;
    color: #495057;
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datetime-picker,
.customDatePickerWidth>div.react-datetime-picker__wrapper,
.customDatePickerWidth>div>div.react-datetime-picker__inputGroup .customDatePickerWidth>div>div.react-datetime-picker__inputGroup input {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}