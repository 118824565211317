.columnNameStyle {
    font-size: 16px;
    font-weight: 600;
    padding:4px;
    text-align: center;
    background-color: rgb(224, 243, 255);
    color: #495057;
    width: 100%;
}

.columnNameClientStyle {
    font-size: 16px;
    font-weight: 600;
    padding:4px;
    text-align: center;
    background-color: rgb(208, 240, 192);
    color: #495057;
    width: 100%;
}

.columnNameCompanyStyle {
    width: 100%;
    font-size: 16px;    
    font-weight: 600;
    padding:4px;
    text-align: center;
    background-color: rgb(255, 229, 180);
    color: #495057
}

.columnNameStateStyle {
    width: 100%;
    padding:4px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    background-color: #B39DDB;
    color: #495057
}

.columnValueStyle {
    font-size: 14px;
    text-align: center
}


.customDatePickerWidth, 
.customDatePickerWidth > div.react-datetime-picker, 
.customDatePickerWidth > div.react-datetime-picker__wrapper,
.customDatePickerWidth > div > div.react-datetime-picker__inputGroup
.customDatePickerWidth > div > div.react-datetime-picker__inputGroup input {
   width: 100%;
   min-width: 100%;
   max-width: 100%;
}