.payment {
  background-color: #f2f2f2;
  padding: 1em;
  border: 2px solid cornflowerblue;
}

.paymentFormatting {
  text-align: center;
}

.breadCrumbContinuarComprar {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  font-size: 15px;
  text-align: center;
}

.logoPayment {
  width: 55%;
}

.circularProgress {
  margin-left: 10px;
}